import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cart-item cart-recipe-item-header" }
const _hoisted_2 = { class: "cart-recipe-item-header-row" }
const _hoisted_3 = { class: "cart-recipe-item-infobox" }
const _hoisted_4 = { class: "cart-item-infobox-subtotal" }
const _hoisted_5 = {
  key: 0,
  class: "cart-item-infobox-button"
}
const _hoisted_6 = {
  key: 1,
  class: "cart-item-infobox-button"
}
const _hoisted_7 = { class: "cart-recipe-item-header-row" }
const _hoisted_8 = {
  key: 0,
  class: "green"
}
const _hoisted_9 = {
  key: 1,
  class: "red"
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  key: 1,
  class: "italic-light"
}
const _hoisted_12 = { class: "cart-item cart-recipe-items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "cart-recipe-item-imagebox",
          onClick: $setup.editRecipe
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 96 960 960"
          }, [
            _createElementVNode("path", { d: "M333.846 830.616h272.308V462.923h-75.385q-29.538 0-47.769 13.962-18.231 13.961-42.231 40.961-30.615 34.385-56.615 50.615-26 16.231-50.308 19.616v242.539Zm-30.769 30.769V587.077Q256.461 576.923 224.384 546t-32.077-80.769q0-41.462 24.731-74.385t67.346-46.616q19.154-41.077 55.423-66.846 36.27-25.769 84.808-25.769 28.077 0 53.193 9.692 25.115 9.693 50.115 30.462 7.692-2.769 14.769-4.269t15.77-1.5q50.615 0 84.538 37.769 33.923 37.769 33.923 88.385 0 17.384-4 31.307-4 13.924-11.077 24.077h50.462q22.692 0 39.039 16.347 16.346 16.346 16.346 39.038v212.308q0 22.692-16.346 39.039-16.347 16.346-39.039 16.346h-75.385v70.769H303.077Zm-80-396.154q0 38 28.692 64.384Q280.461 556 318.461 556q31.231 0 54.154-16.346 22.923-16.347 45.308-42.423 23.769-27.231 49.615-46.154 25.847-18.923 63.231-18.923h115.385q3.846-48.077-21.115-81.731-24.962-33.654-66.577-33.654-11.077 0-21.654 2.423t-16.731 5.5l-15.923-12.923Q490.769 299.615 470.077 291q-20.693-8.616-45.462-8.616-34.231 0-63.923 17.616-29.692 17.615-43.923 48.846l-10.154 20.769-22 7.154q-27.538 9.769-44.538 34.731-17 24.961-17 53.731Zm413.846 294.615h75.385q10.769 0 17.692-6.923t6.923-17.692V522.923q0-10.769-6.923-17.692t-17.692-6.923h-75.385v261.538Zm-303.077 70.77h272.308-272.308Z" })
          ], -1 /* HOISTED */)
        ])),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "cart-recipe-item-infobox-name",
            onClick: $setup.editRecipe
          }, _toDisplayString($props.recipe.name), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_4, "$" + _toDisplayString($setup.subtotal.toFixed(2)), 1 /* TEXT */),
          ($props.editmode)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", {
                  class: "button-icon",
                  onClick: $setup.editRecipe,
                  tabindex: "-1",
                  title: "Edit Recipe"
                }, _cache[1] || (_cache[1] = [
                  _createElementVNode("svg", {
                    xmlns: "http://www.w3.org/2000/svg",
                    height: "40",
                    viewBox: "0 96 960 960",
                    width: "48"
                  }, [
                    _createElementVNode("path", { d: "M203.077 865.231h37.077l441.461-440.692-37.846-37.847-440.692 441.462v37.077Zm544-462.923L666 321.23l42-42q9.308-9.307 22.385-9.307 13.077 0 22.384 9.307l36.308 36.308q9.308 9.308 8.923 22.385-.384 13.077-9.692 23.154l-41.231 41.231Zm-22 21.231L252.615 896h-80.308v-80.308l472.462-472.461 80.308 80.308Zm-62.385-18.693-18.923-18.154 37.846 37.847-18.923-19.693Z" })
                  ], -1 /* HOISTED */)
                ]))
              ]))
            : _createCommentVNode("v-if", true),
          ($props.editmode)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", {
                  class: "button-icon",
                  onClick: _withModifiers($setup.removeRecipe, ["stop"]),
                  tabindex: "-1",
                  title: "Remove Recipe"
                }, _cache[2] || (_cache[2] = [
                  _createElementVNode("svg", {
                    xmlns: "http://www.w3.org/2000/svg",
                    height: "40",
                    viewBox: "0 96 960 960",
                    width: "40"
                  }, [
                    _createElementVNode("path", { d: "M298.615 896q-23.91 0-41.186-17.275-17.275-17.276-17.275-41.186v-510.77H200v-33.846h156.102V265.23h247.796v27.693H760v33.846h-40.154v510.77q0 24.577-16.942 41.519Q685.962 896 661.385 896h-362.77ZM686 326.769H274v510.77q0 10.769 7.179 17.692 7.18 6.923 17.436 6.923h362.77q9.231 0 16.923-7.692Q686 846.77 686 837.539v-510.77ZM394.41 780.205h33.846V408.051H394.41v372.154Zm137.334 0h33.846V408.051h-33.846v372.154ZM274 326.769v535.385-535.385Z" })
                  ], -1 /* HOISTED */)
                ]))
              ]))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        ($props.recipe.millGrains)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_8, "Mill Grains"))
          : (_openBlock(), _createElementBlock("h4", _hoisted_9, "Don't Mill Grains")),
        _createElementVNode("h4", null, [
          _cache[3] || (_cache[3] = _createTextVNode("Special Instructions:")),
          ($props.recipe.specialInstructions.length > 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString($props.recipe.specialInstructions), 1 /* TEXT */))
            : (_openBlock(), _createElementBlock("span", _hoisted_11, "  none"))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.recipe.items, (item) => {
        return (_openBlock(), _createBlock($setup["CartRecipeItem"], {
          key: 'itemSKU-' + item.sku,
          item: item,
          editmode: false
        }, null, 8 /* PROPS */, ["item"]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ], 64 /* STABLE_FRAGMENT */))
}