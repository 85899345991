import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cart-recipe-item" }
const _hoisted_2 = { class: "cart-recipe-item-imagebox" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "cart-recipe-item-infobox-name" }
const _hoisted_5 = { class: "cart-recipe-item-infobox-quantity" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", { src: $setup.productThumb }, null, 8 /* PROPS */, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString($props.item.name), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", null, _toDisplayString($props.item.quantity), 1 /* TEXT */)
    ])
  ]))
}