import { defineComponent as _defineComponent } from 'vue'
import { computed } from "vue"

//types
import type { IShopCartItem } from "types/ShopCartItem"


export default /*@__PURE__*/_defineComponent({
  __name: 'cart-recipe-item',
  props: {
    item: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props

const productThumb = computed((): string => {
	if (props.item?.photos?.v50px) {
		return (
			"https://gnomebrew-cdn.s3.us-east-2.amazonaws.com/images/products/" +
			props.item.photos.v50px
		)
	} else {
		return "https://gnomebrew-cdn.s3.us-east-2.amazonaws.com/images/products/gnome-thumb.jpg"
	}
})

const __returned__ = { props, productThumb, computed }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})