import { IDiscount } from "types/Discount"
import { IReceiptData } from "types/Receipt"
import type { IRecipe } from "types/Recipe"
import type { IShopCartItem } from "types/ShopCartItem"
import { computed, Ref } from "vue"

export { useReceipt }

const taxRates = {
	grocery: 0.0225,
	general: 0.1025,
}

const useReceipt = (
	cart: Ref<IShopCartItem[]>,
	recipes: Ref<IRecipe[]>,
	shipping: Ref<number>,
	totalMillingFee: Ref<number>,
	discounts: Ref<IDiscount[]>,
) => {
	const grossSalesGeneral = computed((): number => {
		let generalSales = 0
		cart.value.forEach((item) => {
			if (!item.grocery && !item.notax) {
				generalSales += item.price * 100 * item.quantity
			}
		})
		recipes.value.forEach((recipe) => {
			recipe.items.forEach((item) => {
				if (!item.grocery && !item.notax) {
					generalSales += item.price * 100 * item.quantity
				}
			})
		})
		return Math.round(generalSales) / 100
	})

	const grossSalesGrocery = computed((): number => {
		let grocerySales = 0
		cart.value.forEach((item) => {
			if (item.grocery) {
				grocerySales += item.price * 100 * item.quantity
			}
		})
		recipes.value.forEach((recipe) => {
			recipe.items.forEach((item) => {
				if (item.grocery) {
					grocerySales += item.price * 100 * item.quantity
				}
			})
		})
		return Math.round(grocerySales) / 100
	})

	const grossSalesNoTax = computed((): number => {
		let noTaxSales = 0
		cart.value.forEach((item) => {
			if (item.notax) {
				noTaxSales += item.price * 100 * item.quantity
			}
		})
		recipes.value.forEach((recipe) => {
			recipe.items.forEach((item) => {
				if (item.notax) {
					noTaxSales += item.price * 100 * item.quantity
				}
			})
		})
		return Math.round(noTaxSales) / 100
	})

	const grossSubtotal = computed((): number => {
		return (
			grossSalesGeneral.value +
			grossSalesGrocery.value +
			grossSalesNoTax.value
		)
	})

	const netSalesGeneral = computed((): number => {
		let generalSales = 0

		cart.value.forEach((item) => {
			if (!item.grocery && !item.notax) {
				generalSales += item.netPrice * 100 * item.quantity
			}
		})
		recipes.value.forEach((recipe) => {
			recipe.items.forEach((item) => {
				if (!item.grocery && !item.notax) {
					generalSales += item.netPrice * 100 * item.quantity
				}
			})
		})
		return Math.round(generalSales) / 100
	})

	const netSalesGrocery = computed((): number => {
		let grocerySales = 0

		cart.value.forEach((item) => {
			if (item.grocery) {
				grocerySales += item.netPrice * 100 * item.quantity
			}
		})
		recipes.value.forEach((recipe) => {
			recipe.items.forEach((item) => {
				if (item.grocery) {
					grocerySales += item.netPrice * 100 * item.quantity
				}
			})
		})
		return Math.round(grocerySales) / 100
	})

	const netSalesNoTax = computed((): number => {
		let noTaxSales = 0

		cart.value.forEach((item) => {
			if (item.notax) {
				noTaxSales += item.netPrice * 100 * item.quantity
			}
		})
		recipes.value.forEach((recipe) => {
			recipe.items.forEach((item) => {
				if (item.notax) {
					noTaxSales += item.netPrice * 100 * item.quantity
				}
			})
		})
		return Math.round(noTaxSales) / 100
	})

	const netSubtotal = computed((): number => {
		return (
			Math.round(
				netSalesGeneral.value * 100 +
					netSalesGrocery.value * 100 +
					netSalesNoTax.value * 100,
			) / 100
		)
	})

	const totalGeneralTax = computed((): number => {
		return Math.round(netSalesGeneral.value * 100 * taxRates.general) / 100
	})

	const totalGroceryTax = computed((): number => {
		return Math.round(netSalesGrocery.value * 100 * taxRates.grocery) / 100
	})

	const totalTax = computed((): number => {
		return (
			Math.round(
				totalGeneralTax.value * 100 + totalGroceryTax.value * 100,
			) / 100
		)
	})

	const grandTotal = computed((): number => {
		let subGrandTotal = netSubtotal.value * 100 + totalTax.value * 100
		subGrandTotal += shipping.value * 100
		if (totalMillingFee.value > 0)
			subGrandTotal += totalMillingFee.value * 100
		return Math.round(subGrandTotal) / 100
	})

	const totalDiscount = computed((): number => {
		let discount = 0

		cart.value.forEach((item) => {
			discount += item.discount * 100 * item.quantity
		})
		recipes.value.forEach((recipe) => {
			recipe.items.forEach((item) => {
				discount += item.discount * 100 * item.quantity
			})
		})
		return Math.round(discount) / 100
	})

	const getReceipt = (): IReceiptData => {
		return {
			grossSales: {
				general: grossSalesGeneral.value,
				grocery: grossSalesGrocery.value,
				noTax: grossSalesNoTax.value,
			},
			grossSalesTotal: grossSubtotal.value,

			taxRates,
			tax: {
				general: totalGeneralTax.value,
				grocery: totalGroceryTax.value,
			},
			totalTax: totalTax.value,

			shipping: shipping.value,
			millingFee: totalMillingFee.value,

			discounts: discounts.value,
			totalDiscount: totalDiscount.value,

			netSales: {
				general: netSalesGeneral.value,
				grocery: netSalesGrocery.value,
				noTax: netSalesNoTax.value,
			},
			netSalesTotal: netSubtotal.value,
			grandTotal: grandTotal.value,
		}
	}

	return {
		grossSubtotal,
		netSubtotal,
		totalTax,
		grandTotal,
		totalDiscount,
		getReceipt,
	}
}
