import { defineStore } from "pinia"
import { ICustomerPreferences } from "types/Customer"
import {
	IDeliveryAddress,
	IDeliveryOrderFulfillmentData,
	IOrderFulfillmentData,
	IPickupOrderFulfillmentData,
	IShipmentOrderFulfillmentData,
	IShippingAddress,
	TDeliveryMethod,
} from "types/Order"
import { computed, ref } from "vue"

export const useFulfillmentStore = defineStore("fulfillment", () => {
	const defaultPreferencesSet = ref(false)
	const deliveryMethod = ref<TDeliveryMethod>("pickup")
	const deliveryAddress = ref<IDeliveryAddress>()
	const shippingAddress = ref<IShippingAddress>()

	const resetFulfillment = () => {
		defaultPreferencesSet.value = false
		deliveryMethod.value = "pickup"
		deliveryAddress.value = undefined
		shippingAddress.value = undefined
	}

	const fulfillment = computed((): IOrderFulfillmentData => {
		if (deliveryMethod.value === "inshop") {
			const inshopFullfillment: IPickupOrderFulfillmentData = {}
			return inshopFullfillment
		} else if (deliveryMethod.value === "pickup") {
			const pickupFullfillment: IPickupOrderFulfillmentData = {}
			return pickupFullfillment
		} else if (deliveryMethod.value === "delivery") {
			if (!deliveryAddress.value) {
				throw new Error("No delivery address")
			}
			const deliveryFullfillment: IDeliveryOrderFulfillmentData = {
				address: deliveryAddress.value,
			}
			return deliveryFullfillment
		} else if (deliveryMethod.value === "shipment") {
			if (!shippingAddress.value) {
				throw new Error("No shipping address")
			}
			const shipmentFullfillment: IShipmentOrderFulfillmentData = {
				address: shippingAddress.value,
			}
			return shipmentFullfillment
		} else {
			throw new Error("Unknown delivery method")
		}
	})

	const setFulfillmentDefaults = (
		customerPreferences: ICustomerPreferences,
	) => {
		deliveryMethod.value = customerPreferences.defaultDeliveryMethod

		if (deliveryMethod.value === "delivery") {
			const defaultDeliveryAddress =
				getDefaultDeliveryAddress(customerPreferences)
			if (defaultDeliveryAddress)
				deliveryAddress.value = defaultDeliveryAddress
		} else if (deliveryMethod.value === "shipment") {
			const defaultShippingAddress =
				getDefaultShippingAddress(customerPreferences)
			if (defaultShippingAddress)
				shippingAddress.value = defaultShippingAddress
		}
		defaultPreferencesSet.value = true
	}

	const setFulfillmentData = (
		updatedDeliveryMethod: TDeliveryMethod,
		updatedFulfillmentData: IOrderFulfillmentData,
	) => {
		deliveryMethod.value = updatedDeliveryMethod
		if (updatedDeliveryMethod === "delivery") {
			deliveryAddress.value = (
				updatedFulfillmentData as IDeliveryOrderFulfillmentData
			).address
		} else if (updatedDeliveryMethod === "shipment") {
			shippingAddress.value = (
				updatedFulfillmentData as IShipmentOrderFulfillmentData
			).address
		}
	}

	const getDefaultDeliveryAddress = (
		preferences: ICustomerPreferences,
	): IDeliveryAddress | undefined => {
		const token = preferences.defaultDeliveryAddressToken
		const address = preferences.deliveryAddresses.find(
			(deliveryAddress) => deliveryAddress.token === token,
		)
		return address
	}

	const getDefaultShippingAddress = (
		preferences: ICustomerPreferences,
	): IShippingAddress | undefined => {
		const token = preferences.defaultShippingAddressToken
		const address = preferences.shippingAddresses.find(
			(shippingAddress) => shippingAddress.token === token,
		)
		return address
	}

	return {
		defaultPreferencesSet,
		fulfillment,
		deliveryMethod,
		deliveryAddress,
		shippingAddress,
		setFulfillmentDefaults,
		setFulfillmentData,
		resetFulfillment,
	}
})
