import { IPublicInventoryItem } from "types/InventoryItem"
import { IShopCategory } from "types/ShopCategory"

export { getItemCanonicalURL }

const getItemCanonicalURL = (
	item: IPublicInventoryItem,
	shopCategories: IShopCategory[],
): string => {
	let href = "/shop/"
	const canonical_category = shopCategories.find(
		(category) => category.id === item.canonical_category,
	)
	if (canonical_category) href += canonical_category.url + "/"
	else href += "items/"
	href += "items/" + item.url + "/" + item.sku
	return href
}
