import { defineComponent as _defineComponent } from 'vue'
import { logger as $log } from "@Plugins/clientLogger"
import { useGetShopCategories } from "@website/api/shopCategories"
import { computed } from "vue"

import { getItemCanonicalURL } from "@website/functions/inventory"
import type { IPublicInventoryItem } from "types/InventoryItem"
import type { IShopCategory } from "types/ShopCategory"
import type { ISearchItem } from "./search.vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'search-item',
  props: {
    searchItem: {},
    resultRank: {}
  },
  emits: ["goToItem"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props

const emit = __emit

const { shopCategories: categories } = useGetShopCategories()
const getCategoryByID = (id: number): IShopCategory | undefined =>
	categories.value?.find((category) => category.id === id)

const image = computed((): string => {
	if (props.searchItem.item.photos?.v50px) {
		return (
			"https://gnomebrew-cdn.s3.us-east-2.amazonaws.com/images/products/" +
			props.searchItem.item.photos.v50px
		)
	} else if (props.searchItem.item.photos?.v250px) {
		return (
			"https://gnomebrew-cdn.s3.us-east-2.amazonaws.com/images/products/" +
			props.searchItem.item.photos.v250px
		)
	} else {
		return "https://gnomebrew-cdn.s3.us-east-2.amazonaws.com/images/products/gnome-thumb.jpg"
	}
})

const href = computed((): string => {
	let href = "/shop/"
	if (props.searchItem.type === "item") {
		const url = getItemCanonicalURL(
			props.searchItem.item as IPublicInventoryItem,
			categories.value,
		)
		return url
	} else if (props.searchItem.type === "category") {
		const category = props.searchItem.item as IShopCategory
		let urlsegments = category.url + "/"
		let currentCategory = category
		while (currentCategory.parent) {
			const parentCategory = getCategoryByID(currentCategory.parent)
			if (parentCategory) {
				urlsegments = parentCategory.url + "/" + urlsegments
				currentCategory = parentCategory
			} else {
				break
			}
		}
		return href + urlsegments
	} else {
		$log.warn("Unknown search item type: " + props.searchItem.type)
		return href
	}
})
const goToItem = (): void => {
	emit("goToItem", props.searchItem.item, href.value, props.resultRank)
}

const __returned__ = { props, emit, categories, getCategoryByID, image, href, goToItem, get $log() { return $log }, get useGetShopCategories() { return useGetShopCategories }, computed, get getItemCanonicalURL() { return getItemCanonicalURL } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})