import { convertPublicInventoryItemToShopCartItem } from "@shared/functions/fnInventoryItem"
import { useGetInventory } from "@website/api/inventory"
import { defineStore } from "pinia"
import { IPublicInventoryItem } from "types/InventoryItem"
import { IShopCartItem } from "types/ShopCartItem"
import { computed, ref } from "vue"
import { useOrderStore } from "./order"

export const useKegeratorBuilderStore = defineStore("kegeratorBuilder", () => {
	const { inventory } = useGetInventory()
	const orderStore = useOrderStore()

	const selectedBaseUnitSKU = ref<IPublicInventoryItem["sku"]>()
	const selectedTowerSKU = ref<IPublicInventoryItem["sku"]>()
	const selectedFaucetSKU = ref<IPublicInventoryItem["sku"]>()
	const selectedFaucetKnobSKU = ref<IPublicInventoryItem["sku"]>()
	const selectedSankeCouplerSKU = ref<IPublicInventoryItem["sku"]>()
	const selectedBallLockConnectionSKUs = ref<number[]>([])

	const kegType = ref<"balllock" | "sanke">("balllock")
	const numberOfFaucets = ref<number>(0)

	const reset = (): void => {
		selectedBaseUnitSKU.value = undefined
		selectedTowerSKU.value = undefined
		selectedFaucetSKU.value = undefined
		selectedFaucetKnobSKU.value = undefined
		selectedBallLockConnectionSKUs.value = []
		selectedSankeCouplerSKU.value = undefined

		kegType.value = "balllock"
		numberOfFaucets.value = 0
	}

	const getInventoryItemBySKU = (
		sku: IPublicInventoryItem["sku"],
	): IPublicInventoryItem | undefined => {
		return inventory.value.find((item) => item.sku === sku)
	}

	const selectedBaseUnitCartItem = computed((): IShopCartItem | undefined => {
		const sku = selectedBaseUnitSKU.value
		if (!sku) return undefined
		const publicInventoryItem = getInventoryItemBySKU(sku)
		if (!publicInventoryItem) return undefined
		const shopCartItem =
			convertPublicInventoryItemToShopCartItem(publicInventoryItem)
		if (!shopCartItem) return undefined
		shopCartItem.quantity = 1
		return shopCartItem
	})

	const selectedTowerCartItem = computed((): IShopCartItem | undefined => {
		const sku = selectedTowerSKU.value
		if (!sku) return undefined
		const publicInventoryItem = getInventoryItemBySKU(sku)
		if (!publicInventoryItem) return undefined
		const shopCartItem =
			convertPublicInventoryItemToShopCartItem(publicInventoryItem)
		if (!shopCartItem) return undefined
		shopCartItem.quantity = 1
		return shopCartItem
	})

	const selectedFaucetCartItem = computed((): IShopCartItem | undefined => {
		const sku = selectedFaucetSKU.value
		if (!sku) return undefined
		const publicInventoryItem = getInventoryItemBySKU(sku)
		if (!publicInventoryItem) return undefined
		const shopCartItem =
			convertPublicInventoryItemToShopCartItem(publicInventoryItem)
		if (!shopCartItem) return undefined
		shopCartItem.quantity = numberOfFaucets.value
		return shopCartItem
	})

	const selectedFaucetKnobCartItem = computed(
		(): IShopCartItem | undefined => {
			const sku = selectedFaucetKnobSKU.value
			if (!sku) return undefined
			const publicInventoryItem = getInventoryItemBySKU(sku)
			if (!publicInventoryItem) return undefined
			const shopCartItem =
				convertPublicInventoryItemToShopCartItem(publicInventoryItem)
			if (!shopCartItem) return undefined
			shopCartItem.quantity = numberOfFaucets.value
			return shopCartItem
		},
	)

	const selectedSankeCouplerCartItem = computed(
		(): IShopCartItem | undefined => {
			const sku = selectedSankeCouplerSKU.value
			if (!sku) return undefined
			const publicInventoryItem = getInventoryItemBySKU(sku)
			if (!publicInventoryItem) return undefined
			const shopCartItem =
				convertPublicInventoryItemToShopCartItem(publicInventoryItem)
			if (!shopCartItem) return undefined
			shopCartItem.quantity = numberOfFaucets.value
			return shopCartItem
		},
	)

	const selectedBallLockConnectionCartItems = computed(
		(): IShopCartItem[] => {
			return selectedBallLockConnectionSKUs.value
				.map((sku) => {
					const publicInventoryItem = getInventoryItemBySKU(sku)
					if (!publicInventoryItem) return undefined
					const shopCartItem =
						convertPublicInventoryItemToShopCartItem(
							publicInventoryItem,
						)
					if (!shopCartItem) return undefined
					shopCartItem.quantity = numberOfFaucets.value
					return shopCartItem
				})
				.filter((item) => item !== undefined) as IShopCartItem[]
		},
	)

	const subTotal = computed((): number => {
		const subTotalInCents = builderCartItems.value.reduce(
			(runningTotalInCents, item) =>
				runningTotalInCents + item.price * 100 * item.quantity,
			0,
		)
		return subTotalInCents / 100
	})

	const builderCartItems = computed((): IShopCartItem[] => {
		const cartItems = [
			selectedBaseUnitCartItem.value,
			selectedTowerCartItem.value,
			selectedFaucetCartItem.value,
			selectedFaucetKnobCartItem.value,
			selectedSankeCouplerCartItem.value,
			...selectedBallLockConnectionCartItems.value,
		].filter((item) => item !== undefined) as IShopCartItem[]
		return cartItems
	})

	const addBuildToCart = (): void => {
		orderStore.addItemsToCart(builderCartItems.value)
		reset()
	}

	return {
		selectedBaseUnitSKU,
		selectedBaseUnitCartItem,
		selectedTowerSKU,
		selectedTowerCartItem,
		selectedFaucetSKU,
		selectedFaucetCartItem,
		selectedFaucetKnobSKU,
		selectedFaucetKnobCartItem,
		selectedBallLockConnectionSKUs,
		selectedBallLockConnectionCartItems,
		selectedSankeCouplerSKU,
		selectedSankeCouplerCartItem,
		kegType,
		numberOfFaucets,
		subTotal,
		builderCartItems,
		addBuildToCart,
		reset,
	}
})
