import { useKegeratorBuilderStore } from "@website/stores/kegeratorBuilder"

const KegeratorBuilder = () =>
	import(
		/* webpackChunkName: "app-route-kegerator-builder" */ /* webpackPrefetch: true */ "@website/components/kegeratorBuilder/KegeratorBuilder.vue"
	)
const KegeratorBuilder_Header = () =>
	import(
		/* webpackChunkName: "app-route-kegerator-builder" */ /* webpackPrefetch: true */ "@website/components/kegeratorBuilder/KegeratorBuilder_Header.vue"
	)
const KegeratorBuilder_BaseUnit = () =>
	import(
		/* webpackChunkName: "app-route-kegerator-builder" */ /* webpackPrefetch: true */ "@website/components/kegeratorBuilder/KegeratorBuilder_BaseUnit.vue"
	)
const KegeratorBuilder_Tower = () =>
	import(
		/* webpackChunkName: "app-route-kegerator-builder" */ /* webpackPrefetch: true */ "@website/components/kegeratorBuilder/KegeratorBuilder_Tower.vue"
	)
const KegeratorBuilder_Faucet = () =>
	import(
		/* webpackChunkName: "app-route-kegerator-builder" */ /* webpackPrefetch: true */ "@website/components/kegeratorBuilder/KegeratorBuilder_Faucet.vue"
	)
const KegeratorBuilder_FaucetKnob = () =>
	import(
		/* webpackChunkName: "app-route-kegerator-builder" */ /* webpackPrefetch: true */ "@website/components/kegeratorBuilder/KegeratorBuilder_FaucetKnob.vue"
	)
const KegeratorBuilder_KegConnection = () =>
	import(
		/* webpackChunkName: "app-route-kegerator-builder" */ /* webpackPrefetch: true */ "@website/components/kegeratorBuilder/KegeratorBuilder_KegConnection.vue"
	)

const routes = [
	{
		path: "/kegerator-builder",
		name: "KegeratorBuilder",
		components: {
			appHeader: KegeratorBuilder_Header,
			default: KegeratorBuilder,
			//appFooter,
		},

		children: [
			{
				path: "/kegerator-builder/",
				name: "KegeratorBuilder_BaseUnit",
				components: { default: KegeratorBuilder_BaseUnit },
			},
			{
				path: "/kegerator-builder/tower",
				name: "KegeratorBuilder_Tower",
				components: { default: KegeratorBuilder_Tower },
				beforeEnter: (to, from, next) => {
					// Redirect to the base unit selection if no base unit has been selected
					if (!useKegeratorBuilderStore().selectedBaseUnitSKU) {
						next({ name: "KegeratorBuilder_BaseUnit" })
					} else {
						next()
					}
				},
			},
			{
				path: "/kegerator-builder/faucet",
				name: "KegeratorBuilder_Faucet",
				components: { default: KegeratorBuilder_Faucet },
				beforeEnter: (to, from, next) => {
					if (!useKegeratorBuilderStore().selectedBaseUnitSKU) {
						next({ name: "KegeratorBuilder_BaseUnit" })
					} else if (!useKegeratorBuilderStore().selectedTowerSKU) {
						next({ name: "KegeratorBuilder_Tower" })
					} else {
						next()
					}
				},
			},
			{
				path: "/kegerator-builder/faucet-knob",
				name: "KegeratorBuilder_FaucetKnob",
				components: { default: KegeratorBuilder_FaucetKnob },
				beforeEnter: (to, from, next) => {
					if (!useKegeratorBuilderStore().selectedBaseUnitSKU) {
						next({ name: "KegeratorBuilder_BaseUnit" })
					} else if (!useKegeratorBuilderStore().selectedTowerSKU) {
						next({ name: "KegeratorBuilder_Tower" })
					} else if (!useKegeratorBuilderStore().selectedFaucetSKU) {
						next({ name: "KegeratorBuilder_Faucet" })
					} else {
						next()
					}
				},
			},
			{
				path: "kegerator-builder/keg-connection",
				name: "KegeratorBuilder_KegConnection",
				components: { default: KegeratorBuilder_KegConnection },
				beforeEnter: (to, from, next) => {
					if (!useKegeratorBuilderStore().selectedBaseUnitSKU) {
						next({ name: "KegeratorBuilder_BaseUnit" })
					} else if (!useKegeratorBuilderStore().selectedTowerSKU) {
						next({ name: "KegeratorBuilder_Tower" })
					} else if (!useKegeratorBuilderStore().selectedFaucetSKU) {
						next({ name: "KegeratorBuilder_Faucet" })
					} else if (
						!useKegeratorBuilderStore().selectedFaucetKnobSKU
					) {
						next({ name: "KegeratorBuilder_FaucetKnob" })
					} else {
						next()
					}
				},
			},
		],
	},
]

export default routes
